import React from "react";

import { WhiteWrapper } from "../components/parts/WhiteWrapper";
import { Container } from "../components/parts/Container";
import { TitleView } from "../components/parts/TitleView";

export const Terms = () => {
  return(
    <WhiteWrapper>
      <Container>
        <TitleView>ご利用規約</TitleView>
        <p>利用規約を必ずお読みください。</p>
        <h2>第1条　はじめに</h2>
        <ol>
          <li>この利用規約（以下「本利用規約」といいます）は、ネクストライブ株式会社（以下「弊社」といいます）が本サイト上で提供するサービスを、ユーザーが利用する際の一切の行為に適用されます。</li>
          <li>本利用規約は、本サービスの利用条件を定めるものです。ユーザーは、本利用規約に従い本サービスを利用するものとします。</li>
          <li>ユーザーは、本サービスを利用することにより、本利用規約の全ての記載内容について同意したものとみなされます。</li>
        </ol>
        <h2>第2条　本利用規約の変更</h2>
        <ol>
          <li>弊社は、弊社の判断により、本利用規約をいつでも任意の理由で変更することができるものとします。</li>
          <li>変更後の利用規約は、弊社が別途定める場合を除いて、本サイト上に表示した時点より効力を生じるものとします。</li>
          <li>ユーザーが、本利用規約の変更の効力が生じた後に本サービスをご利用になる場合には、変更後の利用規約の全ての記載内容に同意したものとみなされます。</li>
        </ol>
        <h2>第3条　個人情報の取扱い</h2>
        <p>弊社は、個人情報を「プライバシーポリシー」に基づき、適切に取り扱うものとします。</p>
        <h2>第4条　通信の秘密</h2>
        <ol>
          <li>弊社は、電気通信事業法（昭和59年法律第86号）第4条に基づき、ユーザーの通信の秘密を守ります。</li>
          <li>弊社は、次の各号に掲げる場合には、当該各号に定める範囲内において前項の守秘義務を負わないものとします。</li>
        </ol>
        <ul>
          <li>(1) 刑事訴訟法（昭和23年法律第131号）又は犯罪捜査のための通信傍受に関する法律（平成11年法律第137号）の定めに基づく強制の処分又は裁判所の命令が行われた場合　当該処分又は裁判所の命令の定める範囲内</li>
          <li>(2) 法令に基づく強制的な処分が行われた場合　当該処分又は命令の定める範囲内</li>
          <li>(3) 特定電気通信役務提供者の損害賠償責任の制限及び発信者情報の開示に関する法律（平成13年法律第137号）第4条に基づく開示請求の要件が充足されたと弊社が判断した場合　当該開示請求の範囲内</li>
          <li>(4) 他人の生命、身体又は財産の保護のために必要があると弊社が判断した場合　他人の生命、身体又は財産の保護のために必要な範囲内</li>
        </ul>
        <h2>第5条　入会</h2>
        <ol>
          <li>本サービスの利用を希望する者は、本利用規約の内容に同意した上で、弊社所定の方法により、入会の申込を行うものとします。</li>
          <li>弊社は、ユーザーに対して新サービスの告知、サービス運営上の事務連絡、その他情報の提供を行います。ただし、ユーザーが情報の提供を希望しない旨を、事前又は事後に弊社所定の方法で通知した場合は、本サービスの提供に必要な場合を除いて、情報の提供を行わないものとします。</li>
        </ol>
        <h2>第6条　入会申込の不承諾</h2>
        <p>弊社は、次の各号のいずれかに該当する場合は、弊社の判断によって、入会申込者の入会申込を承諾しないことがあります。</p>
        <ul>
          <li>(1) 入会申込者が、前条第1項の方法によらずに入会の申込を行った場合</li>
          <li>(2) 入会申込者が、過去に本利用規約又はその他の利用規約等に違反したことを理由として強制退会処分を受けた者である場合</li>
          <li>(3) その他弊社が不適切と判断した場合</li>
        </ul>
        <h2>第7条　退会</h2>
        <ol>
          <li>ユーザーが退会を希望する場合には、ユーザーは、弊社所定の方法により、弊社に退会の申出を行うものとします。</li>
          <li>弊社は、ユーザーが次の各号に掲げるいずれかの行為を行った場合には、弊社の判断によって、ユーザーを強制的に退会させて本サービスの利用をお断りすることがあります。</li>
        </ol>
        <ul>
          <li>(1) 第7条第1項の方法によらずに入会の申込を行ったことが明らかとなった場合</li>
          <li>(2) 本利用規約に違反した場合</li>
          <li>(3) その他弊社が不適切と判断した場合</li>
        </ul>
        <h2>第8条　登録メールアドレス及びパスワードの管理</h2>
        <ol>
          <li>ユーザーは、自己の管理に属する使用可能なメールアドレスを登録メールアドレスとして登録しなければならず、当該登録メールアドレスが自己の管理に属さなくなったときには、自己の管理に属する使用可能な別のメールアドレスに変更しなければならないものとします。</li>
          <li>ユーザーは、自己の登録メールアドレス及びパスワードの不正利用の防止に努めるとともに、その管理について一切の責任を持つものとします。</li>
          <li>登録メールアドレスやパスワードが第三者に利用されたことによって生じた損害等につきましては、弊社に重過失がある場合を除き、弊社はいかなる責任も負いません。</li>
        </ol>
        <h2>第9条　利用環境の整備</h2>
        <ol>
          <li>ユーザーは、本サービスを利用するために必要なあらゆる機器、ソフトウェア、通信手段を自己の責任と費用において、適切に整備するものとします。</li>
          <li>ユーザーは自己の利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を講じるものとします。</li>
          <li>弊社はユーザーの利用環境について一切関与せず、また一切の責任を負いません。</li>
        </ol>
        <h2>第10条　ユーザーの責任</h2>
        <ol>
          <li>ユーザーは、ユーザー自身の自己責任において本サービスを利用するものとし、本サービスを利用してなされた一切の行為及びその結果について一切の責任を負います。</li>
        </ol>
        <h2>第11条　禁止事項</h2>
        <p>ユーザーは、本サービスの利用にあたり、次に掲げる行為を行ってはならないものとします。禁止事項に違反した場合には、強制退会、利用停止等の不利益な措置を採ることがあります。</p>
        <ul>
          <li>(1) 弊社もしくは他者の著作権、商標権等の知的財産権を侵害する行為、又は侵害するおそれのある行為。</li>
          <li>(2) 弊社もしくは他者の財産、プライバシーもしくは肖像権を侵害する行為、又は侵害するおそれのある行為。</li>
          <li>(3) 弊社又は他者のサーバーに負担をかける行為、もしくは、本サービスの運営やネットワーク・システムに支障を与える行為、又はこれらのおそれのある行為。</li>
          <li>(4) その行為が前各号のいずれかに該当することを知りつつ、その行為を助長する行為。</li>
          <li>(5) 法令、公序良俗又は本利用規約もしくはその他の利用規約等に違反し、又は他者の権利を侵害すると弊社が判断する行為。</li>
          <li>(6) その他、弊社が不適切と判断する行為。</li>
        </ul>
        <h2>第12条　サービスの変更等</h2>
        <p>弊社は、弊社の都合により、本サービスをいつでも任意の理由で追加、変更、中断、終了することができます。</p>
        <h2>第13条　利用料金</h2>
        <p>登録及び基本サービスは無料とします。</p>
        <h2>第14条　情報の使用許諾等</h2>
        <ol>
          <li>本サービスを利用して作成された情報の権利（著作権および著作者人格権等の周辺権利）は、創作したユーザーに帰属します。</li>
          <li>弊社は、ユーザーが作成したデータや設定内容を、本サービスの円滑な提供、弊社システムの構築、改良、メンテナンスに必要な範囲内で、使用することができるものとします。</li>
          <li>弊社が前項に定める形で情報を使用するにあたっては、情報の一部を省略することができるものとします。</li>
        </ol>
        <h2>第15条　免責事項</h2>
        <ol>
          <li>弊社は、本サービスの内容の追加、変更、又は本サービスの中断、終了によって生じたいかなる損害についても、一切責任を負いません。アクセス過多、その他予期せぬ要因で表示速度の低下や障害等が生じた場合も同様とします。</li>
          <li>弊社は、ユーザーによって作成された勤怠情報やメッセージ、設定内容を監視したり、保存する義務を負いません。</li>
          <li>弊社は、ユーザーによって作成されるデータや設定内容の合法性、道徳性、信頼性、正確性について責任を負いません。</li>
          <li>弊社は、次に掲げる場合には、情報の内容を閲覧したり、保存したり、第三者に開示すること（以下、本項において「閲覧等」といいます）ができるものとします。弊社は、それによって生じたいかなる損害についても、一切責任を負いません。
            <ul>
              <li>(1) 弊社がユーザーの登録メールアドレスに宛てて閲覧等の同意を求める電子メールを送信した場合であって、次の各号に掲げるいずれかに該当するとき。</li>
              <li>　(ア)当該ユーザーが閲覧等に同意したとき。</li>
              <li>　(イ)弊社が閲覧等の同意を求める電子メールを送信してから7日以内に、これを拒否する旨の当該ユーザーの電子メールでの回答が弊社のメールサーバに到達しなかったとき。ただし、緊急止むを得ない事由が生じたときは除く。</li>
              <li>(2) 本サービスの技術的不具合の原因を解明し、解消するため必要な場合。</li>
              <li>(3) 裁判所や警察などの公的機関から、法令に基づく正式な照会を受けた場合。</li>
              <li>(4) 本利用規約に違反する行為又はそのおそれのある行為が行われ、情報や設定内容を確認する必要が生じたと弊社が判断した場合。</li>
              <li>(5) 人の生命、身体及び財産などに差し迫った危険があり、緊急の必要性があると弊社が判断した場合。</li>
              <li>(6) 第4条第2項各号に掲げる事項に該当する場合。</li>
              <li>(7) その他本サイトを適切に運営するために必要が生じた場合。</li>
            </ul>
          </li>
          <li>弊社は、本利用規約又はその他の利用規約等に違反する行為又はそのおそれのある行為が行われたと信じるに足りる相当な理由があると判断した場合には、当該行為を行ったユーザーの強制退会処分、情報や設定内容の全部もしくは一部の削除、及び公開範囲の変更等を行う場合がありますが、それによって生じたいかなる損害についても、一切責任を負いません。</li>
          <li>本利用規約が消費者契約法（平成12年法律第61号）第2条第3項の消費者契約に該当する場合には、本利用規約のうち、弊社の損害賠償責任を完全に免責する規定は適用されないものとします。この場合においてユーザーに発生した損害が弊社の債務不履行又は不法行為に基づくときは、弊社は当該ユーザーが直接被った損害を上限として損害賠償責任を負うものとします。ただし、弊社に重過失がある場合に限ります。</li>
        </ol>
        <h2>第16条　本利用規約違反行為等への対処</h2>
        <ol>
          <li>本利用規約又はその他の利用規約等に違反する行為を発見した場合には、お問い合わせフォームから通報してください。</li>
          <li>ユーザーが本利用規約に違反したと認められる場合、その他弊社が必要と認める場合は、弊社は当該ユーザーに対し以下の対処を講ずることがあります。
            <ul>
              <li>(1) 本利用規約に違反する行為等を止め、同様の行為を繰り返さないことを要求すること。</li>
              <li>(2) データ･設定等の自発的削除・訂正を求めること。</li>
              <li>(3) 強制退会処分とすること。</li>
              <li>(4) 入会申込者の入会申込を承諾しないこと。</li>
            </ul>
          </li>
          <li>ユーザーは、弊社が第8条、第14条、本条第2項の規定に基づいて行った本利用規約に違反する行為等への対処について、異議を申し立てることはできないものとします。</li>
        </ol>
        <h2>第17条　準拠法</h2>
        <p>本利用規約の準拠法は、日本法とします。</p>
      </Container>
    </WhiteWrapper>
  )
}
