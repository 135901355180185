import React from "react";

import { Header } from "../components/parts/Header";
import { Terms } from "../components/Terms";
import { Footer } from "../components/Footer";

import { Layout } from "../components/parts/Layout";

const TermsPage = () => {
  return (
    <Layout>
      <Header
        title={"ご利用規約"}
        description={"ご利用規約"}
      />
      <Terms />
      <Footer />
    </Layout>
  )
}

export default TermsPage
